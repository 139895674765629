<template>
    <static-layout title="Contact"
                   :show-footer-contact="false"
                   :show-footer-logo="false"
    >
        <div v-for="(section, key) in sections" :key="key" class="static-content">
            <div v-html="section.content"></div>
        </div>
    </static-layout>
</template>

<script>
import PageSection from "@/models/PageSection";
import StaticLayout from "@/layouts/StaticLayout";

export default {
    name: "Contact",
    components: {StaticLayout},
    data: function () {
        return {
            sections: [],
        }
    },
    methods: {
        fetchPageSections() {
            PageSection
                .where('page', 'contact')
                .get()
                .then(sections => this.sections = sections)
                .catch((e) => console.log(e))
        }
    },
    created() {
        this.fetchPageSections()
    }
}
</script>

<style scoped module lang="scss">

</style>
